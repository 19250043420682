import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import PostsList from "../components/posts"
import Img from "gatsby-image"
import ImageIcon from "../images/icons/image-icon.svg"

const PostWrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  max-height: 100vh;
  overflow-y: auto;
  padding: ${props => props.theme.wrapper.paddingY} ${props => props.theme.wrapper.paddingX};
`

const PostHeader = styled.div`
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
`

const PostTitle = styled.h1``

const PostDate = styled.h5`
  flex: 1 0 auto;
  max-width: 170px;
  text-align: right;
`

const PostBody = styled.div`
`

const PostImageContainer = styled.div`
  margin-bottom: 1.15rem;
  margin-left: 1em;
  margin-right: auto;
  position: relative;
`

const PostImageIcon = styled.img`
  cursor: pointer;
  height: 2rem;
  margin: 0;
`

const PostImageInner = styled.div`
  background-color: rgba(142,166,141,0.5);
  box-shadow: 0 0 0 10000px rgba(0,0,0,.5);
  height: 300px;
  opacity: 0;
  position: fixed;
  left: 50%;
  margin-left: -200px;
  margin-top: -150px;
  top: 50%;
  visibility: hidden;
  width: 400px;
  z-index: -1;
  transition: opacity .15s ease, visibility 0s, z-index .15s;
  ${PostImageContainer}:hover & {
    opacity: 1;
    visibility: visible;
    z-index: 1000;
  }
`

const PostImage = (props) => {
  return (
    <PostImageContainer>
      <PostImageIcon src={ImageIcon} alt="Show images" />
      <PostImageInner>
        <Img fluid={props.image} />
      </PostImageInner>
    </PostImageContainer>
  )
}

export default function Template({ data }) {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark
  const featuredImage = frontmatter?.featuredImage?.childImageSharp?.fluid

  return (
    <Layout>
      <PostsList />
      <PostWrapper>
        <PostHeader>
          <PostTitle>{frontmatter.title}</PostTitle>
          {featuredImage &&
            <PostImage image={featuredImage}></PostImage>
          }
          <PostDate>{frontmatter.date}</PostDate>
        </PostHeader>
        <hr />
        <PostBody dangerouslySetInnerHTML={{ __html: html }} />
      </PostWrapper>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`
